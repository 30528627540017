.section-education {
    height: 100%;
    background-color: $color-grey-light-2;
    padding: 6rem 3rem;
    display: flex;
    flex-direction: column;
    z-index: 5;

    &__title {
      font-size: 4.5rem;
      position: relative;
      width: fit-content;
      align-self: center;

      &--highlight {
        @include highlightBar;
        width: 22.5rem;
        @include respond(tab-port) {
          width: 18.4rem;
          top: 5.5rem;
        }
      }
    }

    @include respond(tab-port) {
      padding: 3rem 3rem;
    }

}

.education {
    width: 75%;
    box-shadow: 0 3rem 6rem rgba($color-black, 0.1);
    background-color: rgba($color-white, .6);
    margin: 6rem auto;
    border-radius: 3px;
    padding: 6rem;
    font-size: $default-font-size;
    transform: skewX(-12deg);

    @include respond(phone) {
      width: 97%;
      transform: skewX(0);
    }

    &__container {
        margin: auto 0;
    }

    &__link {
      text-decoration: none;
      color: $color-grey-dark-1;
    }


    &__shape {
        width: 15rem;
        height: 15rem;
        float: left;
        position: relative;
    
        // shape-outside requires element to have width/height and float prop
        -webkit-shape-outside: circle(50% at 50% 50%);
        shape-outside: circle(50% at 50% 50%);
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
    
        transform: translateX(-3rem) skewX(12deg);

        @include respond(phone) {
          transform: translateX(-3rem) skewX(0);
        }
      }
    
      &__img {
        height: 100%;
        transform: translateX(0rem) scale(1.4);
        transition: all .5s;
      }
    
      &__text {
        transform: skewX(12deg);

        @include respond(phone) {
          transform: skewX(0);
        }
      }
    
      &__caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: $color-white;
        text-transform: uppercase;
        font-size: 1.7rem;
        text-align: center;
        opacity: 0;
        transition: all .5s;
        // Hides the backside of an element, can be used to fix animation glitch
        backface-visibility: hidden;
      }
    
      &:hover &__caption {
          opacity: 1;
          transform: translate(-50%, -50%); 
          cursor: pointer;

          @include respond(tab-land) {
            opacity: 0;
            transform: none;
          }
      }
    
      &:hover &__img {
        transform: translateX(0rem) scale(1.1);
        filter: blur(3px) brightness(80%);
        cursor: pointer;

        @include respond(tab-land) {
          transform: translateX(0rem) scale(1.4);
          filter: none;          
        }
      }

      &:hover,
      &:active {
        cursor: pointer;
      }
}