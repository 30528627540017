.bg-photo {

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -100;
    
    &__content {
        height: 100%;
        width: 100%;
        object-fit: cover; // will maintain aspect-ratio while filling content
        overflow: hidden;
    }

    &__image {
        @include respond(tab-land) {
            transform: translateX(-10rem) translateY(-4rem);
        }

        @include respond(big-desktop) {
            transform: translateX(-10rem) translateY(-4rem);
        }
        
    }
}

.bg-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color-white, .2);
    z-index: -99;
    box-shadow: 0 0 18px 23px $color-grey-light-2 inset;
}