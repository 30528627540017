@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-6rem);
  }

  80% {
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}