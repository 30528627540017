// not used, starter mixin
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin cardpop($bg-color, $text-color) {
  transform: scale(1.3) rotate(5deg);
  background-color: rgba($bg-color, 0.8);
  color: $text-color;
  box-shadow: -4px 1rem 2rem 5px $color-black;
}

@mixin centerfix {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin subtleBoxShadow {
  box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
}

@mixin highlightBar {
  width: 25rem;
  height: 1rem;
  top: 6.5rem;
  left: -2rem;
  border-radius: 2rem;
  background-color: rgba($color-primary-orange-1, 0.6);
  position: absolute;
}

// MEDIA QUERY MANAGER

/*
  0 - 600px:    Phone
  600 - 900px:  Tablet Portrait
  900 - 1200px: Tablet Landscape
  [1200 - 1800px]: NORMAL STYLES APPLY
  1800 + :      Big Desktop

  $breakpoint choices:
    - phone
    - tab-port
    - tab-land
    - big-desktop

  ORDER: base + typography > general layout > grid > page layout > components
*/

// 1em = 16px
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px - 600 / 16 = 37.5
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } // 900px - 900 / 16 = 56.25
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } // 1200px - 1200 / 16 = 75
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px - 1800 / 16 = 112.5
  }
}
