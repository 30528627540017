// COLORS

// Primary green hue
$color-primary-grn: #28b485;
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

// Primary orange (golden gate)
$color-secondary-dark: #ff7730;
$color-primary-orange-1: #ff4f00; // International Orange (golden gate bridge)
$color-primary-orange-2: #F57A41;
$color-primary-orange-3: #D1612E;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark-1: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-grey-dark-bg: rgba(66%, 70%, 74%, 0.3);

$color-white: #fff;
$color-black: #000;

//FONT
$default-font-size: 1.6rem;