// Main About Me section
.about {
  padding: 10rem 8rem;
  background-color: $color-grey-light-2;
  height: 100%;
  letter-spacing: .3rem;
  text-align: center;
  
  // Padding lowered on touch screens
  @include respond(tab-port) {
    padding: 6rem 3rem;
  }

  &__description {
    font-size: 2.5rem;
    text-indent: 4rem;
    text-align: justify;
    letter-spacing: 3px;
    margin-bottom: 5rem;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    @include respond(tab-port) {
      font-size: 2.5rem;
      line-height: 5.5rem;
    }
  }

  &__title {

    &--main {
      font-size: 4.5rem;
      display: inline-block;
      position: relative;

      &--highlight {
        @include highlightBar;

        @include respond(tab-port) {
          width: 18.4rem;
          top: 5.5rem;
        }
      }

      @include respond(tab-port) {
        font-size: 4rem;
      }
    }

    &--sub {
      
    }
  }
}
