// main primary heading container

.header {
  
  &__primary {
    height: 97vh;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    justify-content: flex-end;
    padding: 0 4rem 0 4rem;
    margin-right: 2rem;
    width: 100%;
    position: relative;
    opacity: .89;

    @include respond(phone){
      height: 96vh;
      justify-content: flex-start;
    }

    .icon-box {
      border-radius: 10rem;
      // background-color: $color-primary-orange-1;
      overflow: hidden;
      height: 18rem;
      width: 18rem;
      margin-right: 3.5rem;
      position: relative;
      // z-index: 100;
      transition: all .3s;
      border: .5rem solid $color-primary-orange-1;
      

      @include subtleBoxShadow;

      @include respond(tab-port) {
        margin-bottom: 2rem;
      }

      @include respond(phone) {
        height: 18rem;
        width: 18rem;
        margin-bottom: 2rem;
      }
      
      &__icon {
        height: 36rem;
        transform: translateX(-1rem) translateY(-8rem) rotate(-7deg);
        @include respond(phone) {
          height: 27rem;
          transform: translateX(1rem) translateY(-4rem) rotate(-7deg) scale(1.2);
        }
      }
    }
  }

  &__container {
    display: flex;
    align-self: center;
    animation: moveInLeft 1.3s ease-out;
    width: auto;
    letter-spacing: .5rem;
    margin-right: 5rem;

    @include respond(tab-port) {
      flex-direction: column;
      letter-spacing: 0;
      margin-bottom: 8rem;
    }

    @include respond(tab-land) {
      margin-right: 0;
    }

    @include respond(big-desktop) {
      margin-right: 10rem;
      margin-bottom: 3rem;
    }
  }

  &__title {
    // align-self: flex-end;
    display: flex;
    flex-direction: column;
    width: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: baseline;
    }

    @include respond(phone) {
      flex-direction: column;
    }

    &--main {
      color: $color-black;
      font-size: 5rem;
      transition: all .3s;
  
      @include respond(tab-port) {
        align-self: flex-start;
        margin-bottom: .5rem;
        font-size: 4rem;
      }

      @include respond(phone) {
        align-self: flex-start;
        margin-bottom: 3rem;
        font-size: 2rem;
      }
  
      &:hover {
        @include cardpop($color-primary-orange-1, cornflowerblue); // pop out animation
      }
    }
  
    &--sub {
      color: $color-grey-dark-3;
      // align-self: flex-end;
      font-size: 3rem;
      width: fit-content;
      transition: all .3s;
  
      @include respond(tab-port) {
        margin-left: 0;
        font-size: 5rem;
      }

      @include respond(phone) {
        margin-left: 0;
        font-size: 2.5rem;
        width: 23rem;
      }
  
      &:hover {
        @include cardpop($color-primary-orange-1, cornflowerblue); // pop out animation
      }
    }
  }

}

.sf-icon {
  position: absolute;
  z-index: 100;
  top: 6rem;
  left: 6rem;

  &__icon {
    height: 9rem;
  }
}


