.section-projects {
  height: 100%;
  background-color: $color-grey-light-2;
  padding: 6rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.projects {
  display: flex;
  justify-content: center;
  padding: 6rem 3rem;
  flex-wrap: wrap;

  @include respond(tab-port) {
    flex-direction: column;
    height: 100%;
    padding: 6rem 0rem;
  }

  &__title-1 {
    font-size: 4.5rem;
    position: relative;
    width: fit-content;
    align-self: center;

    &--highlight {
      @include highlightBar;
      width: 20rem;
      @include respond(tab-port) {
        width: 18.4rem;
        top: 5.5rem;
      }
    }
  }

  &__title {
    margin: 2rem 0;
  }

  &__text {
    margin: 0 2rem 2rem 2rem;
  }

  &__logo {
    @include respond(tab-port) {
      padding: 2rem;
    }

    &__icon {
      width: 4rem;
    }

    &__icon:first-child {
      margin: 0 3rem;
    }
  }

  &__container {
    background-color: rgba($color-white, 0.8);
    font-size: 1.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
    transition: transform 0.3s;
    align-self: center;
    width: 33rem;
    height: 55rem;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    @include respond(tab-port) {
      // width: 86vw;
      width: 75vw;
      height: auto;
    }

    @include respond(phone) {
      width: 86vw;
    }

    &:hover {
      transform: translateY(-1.5rem) scale(1.03);
    }

    @include respond(tab-land) {
      &:hover {
        transform: none;
      }
    }
  }

  &__screen {
    margin-bottom: -1rem;

    &--img {
      width: 100%;
      height: auto;
    }
  }
}
