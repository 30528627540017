.section-technologies {
  height: 102vh;
  width: 100%;
  background-color: $color-grey-light-2;
  padding: 6rem 0 24rem 0;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 4.5rem;
    position: relative;
    width: fit-content;
    align-self: center;
    margin-bottom: 8rem;

    &--highlight {
        @include highlightBar;
        width: 29rem;
        @include respond(tab-port) {
          width: 23rem;
          top: 5.5rem;
        }
    }
  }

  @include respond(phone) {
    padding: 9rem 2rem;
  }

  .technologies {
    width: 100%;
    height: 100%;
    display: flex;
    // padding: 2rem;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-around;

    &__icon {
      width: 15rem;
      height: 15rem;
      display: flex;
      margin-bottom: 4rem;

      @include respond(phone) {
        width: 10rem;
        height: 10rem;
      }

      &--img {
        width: 15rem;
        height: auto;

        @include respond(phone) {
          width: 10rem;
        }
      }
    }
  }
}
