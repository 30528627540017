.footer {
    background-color: $color-grey-dark-3;
    padding: 8rem 0 8rem 0;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        justify-content: center;
    }

    &__logo-box {
        width: 34%;
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto 8rem;

        @include respond(phone) {
            width: 52%;
        }
    }

    &__logo {
        width: 4rem;
        height: auto;

        &:not(:last-child) {
            margin-right: 6rem;
        }
    }

    &__copyright {
        color: $color-grey-light-1;
        border-top: 1px solid $color-grey-dark-1;
        padding-top: 2rem;
        width: 80%;
    }
}