.close {
  @include centerfix;
  left: 47.5% !important;
  position: fixed;
  width: 80vw;
  height: 35vh;
  border-radius: 3px;
  z-index: 5000;
  visibility: hidden;

  @include respond(tab-port) {
    left: 46.5% !important;
    width: 73vw;
  }
}

.popup {
  background-color: rgba($color-black, 0.3);
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  &__content {
    @include centerfix;
    @include subtleBoxShadow;
    background-color: $color-white;
    width: 75%;
    border-radius: 3px;
    display: table;
    overflow: hidden;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    transition: opacity visibility 1s;
  }

  &__left {
    width: 25rem;
    align-self: baseline;
    margin-left: 7rem;
  }

  &__right {
    display: table-cell;
    vertical-align: middle;
    padding: 3rem 9rem;

    @include respond(tab-port) {
      padding: 3rem 4rem;
    }
  }

  &__img {
    display: block;
    width: 65%;
  }

  &__text {
    font-size: 1.4rem;
    margin-bottom: 4rem;

    column-count: 2;
    column-gap: 4rem; // 1em = 14px
    column-rule: 1px solid $color-grey-light-2;

    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  // Anchor element target #popup id
  // Open states
  &:target {
    opacity: 1;
    visibility: visible;
  }

  &:target &__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    width: 75vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @include respond(tab-port) {
      width: 90vw;
    }
  }

  &__close {
    &:link,
    &:visited {
      color: $color-grey-dark-1;
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      font-size: 5rem;
      text-decoration: none;
      display: inline-block;
      transition: all 0.2s;
      line-height: 1;
    }

    &:hover {
      color: $color-primary;
    }
  }
}
