.email {
  height: 85vh;
  padding: 15rem 0;
  background-image: linear-gradient(
    to right bottom,
    rgba($color-primary-orange-2, .7),
    rgba($color-primary-orange-1, .9),
  );
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(tab-port) {
    padding: 10rem 0;
    height: 72vh;
  }
  
  // background-color: rgba(#747A92, .19);
  // background-color: rgba(#635C76, .19);
}

.book {
  background-image: linear-gradient(
    105deg,
    rgba($color-white, 0.9) 0%,
    rgba($color-white, 0.9) 50%,
    transparent 50%,
  ), url('img/sf-background.jpg');
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);
  height: 50rem;
  width: 75%;

  @include respond(tab-port) {
    background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 65%,
      transparent 65%
    ),
    url('img/sf-background.jpg'); 
    background-size: cover;
  }

  @include respond(phone) {
    background-image: linear-gradient(
      to right ,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 100%
    );
    width: 85%;
  }

  @include respond(big-desktop) {
    width: 60%;
  }

  &__heading {
    font-size: 3rem;
    margin-bottom: 5rem;
    text-align: center;
  }

  &__form {
    width: 50%;
    padding: 3rem;

    @include respond(tab-port) {
      width: 65%;
    }

    @include respond(phone) {
      width: 100%; 
    }
  }

  .form {
    &__input {
      font-size: 1.5rem;
      font-family: inherit;
      padding: 1.5rem 2rem;
      color: inherit;
      border-radius: 2px;
      background-color: rgba($color-white, 0.5);
      border: none;
      border-bottom: 3px solid transparent;
      width: 90%;
      display: block;
      transition: all 0.3s;

      &--textarea {
        height: 10rem;
        resize: none;
      }

      @include respond(tab-port) {
        width: 100%;
      }

      &::-webkit-input-placeholder {
        color: $color-grey-dark-2;
      }

      &:focus {
        outline: none;
        box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
        border-bottom: 3px solid $color-primary;

        &:invalid {
          border-bottom: 3px solid $color-secondary-dark;
        }
      }
    }

    // &:nth-child(1) {
    //   margin-top: 4rem;
    // }

    &__group:last-child {
      margin-top: 3rem;

      @include respond(phone) {
        text-align: center;
      }
      
    }

    &__group:not(:last-child) {
      margin-bottom: 1.5rem;

      @include respond(tab-port) {
        margin-bottom: 2rem;
      }
    }

    &__label {
      font-size: 1.2rem;
      font-weight: 700;
      margin-left: 2rem;
      margin-top: 0.7rem;
      display: block;
      transition: all 0.3s;
    }

    &__input:placeholder-shown + &__label {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-4rem);
    }

    &__radio-group {
      width: 49%;
      display: inline-block;
    }

    &__radio-input {
      display: none;
    }

    &__radio-label {
      font-size: $default-font-size;
      cursor: pointer;
      position: relative;
      padding-left: 4.5rem;
    }

    &__radio-button {
      height: 3rem;
      width: 3rem;
      border: 5px solid $color-primary;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 0;
      top: -0.4rem;

      &::after {
        content: "";
        display: block;
        height: 1.3rem;
        width: 1.3rem;
        border-radius: 50%;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        @include centerfix;
        background-color: $color-primary;
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
  }

  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }
}
