.navigation {
    &__checkbox {
      display: none;
    }
  
    &__button {
      background-color: $color-primary-orange-1;
      height: 7rem;
      width: 7rem;
      position: fixed;
      top: 6rem;
      right: 8rem;
      border-radius: 50%;
      z-index: 2000;
      box-shadow: 0 1rem 3rem rgba($color-black, .1);
      text-align: center;
      cursor: pointer;

      @include respond(tab-port) {
        top: 4rem;
        right: 4rem;
      }
  
      @include respond(phone) {
        top: 3rem;
        right: 3rem;
      }
    }
  
    &__background {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      position: fixed; // doesn't change position when scrolling page, is out of flow
      top: 6.5rem;
      right: 8.5rem;
      background-image: radial-gradient(
        $color-primary-orange-2,
        $color-primary-orange-3
      ); // produces circular gradient
      z-index: 1000;
      transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
  
      @include respond(tab-port) {
        top: 4.5rem;
        right: 4.5rem;
      }
  
      @include respond(phone) {
        top: 3.5rem;
        right: 3.5rem;
      }
    }
  
    &__nav {
      height: 100vh;
      //! Can do but not needed
      // width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1500;
      opacity: 0;
      visibility: hidden;
      width: 0;
      transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  
    &__list {
      @include centerfix; // will center an item completely
      list-style: none;
      text-align: center;
      width: 100%;
    }
  
    &__item {
      margin: 1rem;
    }
  
    &__link {
      &:link,
      &:visited {
        display: inline-block;
        font-size: 3rem;
        font-weight: 300;
        padding: 1rem 2rem;
        color: $color-white;
        text-decoration: none;
        text-transform: uppercase;
        background-size: 220%;
        background-image: linear-gradient(
          120deg,
          transparent 0%,
          transparent 50%,
          $color-white 50%
        );
        transition: all 0.4s;
  
        span {
          margin-right: 1.5rem;
          display: inline-block;
        }
      }
  
      &:hover,
      &:active {
        background-position: 100%;
        color: $color-primary-orange-1;
        transform: translateX(1rem);
      }
    }
  
    // FUNCTIONALITY
    // ~ will select specified sibling element 
    &__checkbox:checked ~ &__background {
      transform: scale(80);
    }
  
    &__checkbox:checked ~ &__nav {
      opacity: 1;
      width: 100%;
      visibility: visible;
    }
  
    // ICON
    &__icon {
      position: relative;
      margin-top: 3.5rem;
  
      &,
      &::before,
      &::after{
        width: 3rem;
        height: 2px;
        background-color: $color-grey-dark-3;
        display: inline-block;
      }
  
      &::before,
      &::after{
        content: "";
        position: absolute;
        left: 0;
        transition: all .2s;
      }
  
      &::before { top: -.8rem; }
      &::after { top: .8rem; }
  
    }
  
    &__button:hover &__icon::before {
      top: -1rem;
    }
  
    &__button:hover &__icon::after {
      top: 1rem;
    }
  
    &__checkbox:checked + &__button &__icon {
      background-color: transparent;
    }
  
    &__checkbox:checked + &__button &__icon::before {
      top: 0;
      transform: rotate(135deg);
    }
  
    &__checkbox:checked + &__button &__icon::after {
      top: 0;
      transform: rotate(-135deg);
    }
  }
  